#typing-animation {
  white-space: wrap;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

#typing-animation:after {
  content: "|";
  color: #000e;
  height: 100%;
  margin-left: 0;
  position: absolute;
  transform: scaleX(.5);
}

#typing-animation.blinking:after {
  animation: .6s step-end infinite blink;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
/*# sourceMappingURL=index.7133d9f6.css.map */
