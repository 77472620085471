#typing-animation {
    white-space: wrap;
    display: inline-block;
    position: relative;
    overflow: hidden;
}

#typing-animation::after {
    content: "|";
    position: absolute;
    margin-left: 0rem;
    height: 100%;
    color: rgba(0, 0, 0, 0.935);
    transform: scaleX(0.5);
}

#typing-animation.blinking::after {
    animation: blink 0.6s steps(1) infinite;
}

@keyframes blink {
    50% {
    opacity: 0;
    }
}
